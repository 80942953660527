import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5"},[_c('DialogErrorBalance',{attrs:{"show":_vm.isShowError},on:{"close":_vm.closeDialog}}),_c(VBtn,{attrs:{"color":"primary","block":""},on:{"click":function($event){_vm.wetWorkDialog = true}}},[_vm._v("Complete Analysis Process")]),_c('Dialog',{attrs:{"show":_vm.wetWorkDialog},on:{"close":function($event){_vm.wetWorkDialog = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div')]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"d-flex justify-center pb-5 pt-5"},[_c(VImg,{attrs:{"src":require('@/assets/debio-logo.png'),"max-width":"50"}})],1),_c('div',{staticClass:"pb-5",attrs:{"align":"center"}},[_vm._v("Are you sure you want to complete the Analysis process?")]),_c('div',{staticClass:"ml-5 mr-5 pb-1 d-flex justify-space-between mt-5"},[_c('div',[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" Estimated Transaction Weight ")]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"primary","size":"12"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}])},[_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v("Total fee paid in DBIO to execute this transaction.")])])],1),_c('div',[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(Number(_vm.fee).toFixed(4))+" DBIO ")])])])]},proxy:true},{key:"actions",fn:function(){return [_c(VCol,{attrs:{"col":"12","md":"6"}},[_c('Button',{attrs:{"loading":_vm.isProcessing,"elevation":"2","dark":""},on:{"click":_vm.processDnaSample}},[_vm._v("Yes")])],1),_c(VCol,{attrs:{"col":"12","md":"6"}},[_c('Button',{attrs:{"disabled":_vm.isProcessing,"elevation":"2","color":"purple","dark":""},on:{"click":function($event){_vm.wetWorkDialog = false}}},[_vm._v("No")])],1)]},proxy:true}])}),_c('DialogAlert',{attrs:{"show":_vm.wetWorkAlertDialog,"btnText":"Continue","textAlert":"Analysis process has been completed.","imgPath":"success.png","imgWidth":"50"},on:{"toggle":function($event){_vm.wetWorkAlertDialog = $event},"close":_vm.closeWetWorkAlertDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }