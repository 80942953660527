//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    filename: String,
    ipfsUrl: String,
    hideDelete: Boolean,
    viewOnly: Boolean
  },
  methods: {
    onDeleteClick() {
      this.$emit("delete", this.file);
    },
    onEditClick() {
      this.$emit("edit");
    }
  }
};