//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from "vuex";
import { processDnaSample, processDnaSampleFee } from "@/lib/polkadotProvider/command/geneticTesting";
import DialogAlert from "@/components/Dialog/DialogAlert";
import DialogErrorBalance from "@/components/Dialog/DialogErrorBalance";
export default {
  name: "ReceiveSpecimen",
  components: {
    DialogAlert,
    DialogErrorBalance
  },
  props: {
    specimenNumber: String,
    isBiological: Boolean
  },
  data: () => ({
    isShowError: false,
    isLoading: false,
    valid: false,
    confirmSpecimenNumber: "",
    confirmSpecimenNumberRule: password => val => !!password && password == val || "Specimen number must match.",
    specimenAlertDialog: false,
    fee: 0,
    nextStatus: ""
  }),
  computed: {
    ...mapGetters({
      api: "substrate/getAPI",
      pair: "substrate/wallet"
    }),
    ...mapState({
      web3: state => state.metamask.web3
    })
  },
  async mounted() {
    this.getNextStatus();
    this.getProcessDnaSampleFee();
  },
  methods: {
    getNextStatus() {
      if (this.valid) {
        if (!this.isBiological) {
          this.nextStatus = "Arrived";
          return;
        }
      }
      this.nextStatus = "QualityControlled";
    },
    async receiveDnaSample() {
      try {
        this.isLoading = true;
        await processDnaSample(this.api, this.pair, this.specimenNumber, this.nextStatus, () => {
          this.isLoading = false;
          this.specimenAlertDialog = true;
        });
      } catch (err) {
        if (err.message === "1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low") {
          this.isShowError = true;
        }
      }
    },
    closeDialog() {
      this.isShowError = false;
    },
    async getProcessDnaSampleFee() {
      const fee = await processDnaSampleFee(this.api, this.pair, this.specimenNumber, this.nextStatus);
      this.fee = this.web3.utils.fromWei(String(fee.partialFee), "ether");
    }
  }
};