//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from "vuex";
import { rejectDnaSample, processDnaSample, processDnaSampleFee } from "@/lib/polkadotProvider/command/geneticTesting";
import { queryDnaSamples } from "@/lib/polkadotProvider/query/geneticTesting";
import Dialog from "@/components/Dialog";
import DialogAlert from "@/components/Dialog/DialogAlert";
import Button from "@/components/Button";
import DialogErrorBalance from "@/components/Dialog/DialogErrorBalance";
const englishAlphabet = val => val && /^[A-Za-z0-9!@#$%^&*\\(\\)\-_=+:;"',.\\/? ]+$/.test(val) || "This field can only contain English alphabet";
export default {
  name: "ProcessSpecimen",
  components: {
    Dialog,
    DialogAlert,
    DialogErrorBalance,
    Button
  },
  props: {
    specimenNumber: String,
    specimenStatus: String
  },
  data: () => ({
    isLoading: false,
    isShowError: false,
    qcDialog: false,
    qcCompletionDialog: false,
    rejectionDialog: false,
    rejectionStatementDialog: false,
    rejectionConfirmationDialog: false,
    rejectionTitle: "",
    rejectionDescription: "",
    rejectionAlertDialog: false,
    fee: 0,
    nextStatus: "",
    status: ["Registered", "Arrived", "QualityControlled", "WetWork", "ResultReady"]
  }),
  computed: {
    ...mapGetters({
      api: "substrate/getAPI",
      pair: "substrate/wallet"
    }),
    ...mapState({
      web3: state => state.metamask.web3
    }),
    rejectionTitleRules() {
      return [val => !!val || "Title is required", val => val && val.length <= 100 || "This field only allows 100 characters", englishAlphabet];
    },
    rejectionDescRules() {
      return [val => !!val || "Description is required", val => val && val.length <= 255 || "This field only allows 255 characters", englishAlphabet];
    }
  },
  async created() {
    await this.setStatus();
    try {
      const dnaSample = await queryDnaSamples(this.api, this.specimenNumber);
      if ((dnaSample === null || dnaSample === void 0 ? void 0 : dnaSample.status) !== "Rejected") await this.getFee();
      this.rejectionTitle = dnaSample === null || dnaSample === void 0 ? void 0 : dnaSample.rejectedTitle;
      this.rejectionDescription = dnaSample === null || dnaSample === void 0 ? void 0 : dnaSample.rejectedDescription;
    } catch (err) {
      console.log(err);
    }
  },
  methods: {
    async setStatus() {
      this.status.forEach((e, i) => {
        if (e === this.specimenStatus) {
          this.nextStatus = this.status[i + 1];
        }
      });
    },
    async getFee() {
      const fee = await processDnaSampleFee(this.api, this.pair, this.specimenNumber, this.nextStatus);
      this.fee = this.web3.utils.fromWei(String(fee.partialFee), "ether");
    },
    closeQcDialog() {
      this.qcDialog = false;
      this.qcCompletionDialog = true;
    },
    closeDialog() {
      this.isShowError = false;
    },
    async closeQcCompletionDialogProceed() {
      try {
        this.isLoading = true;
        await processDnaSample(this.api, this.pair, this.specimenNumber, "QualityControlled", () => {
          this.isLoading = false;
          this.qcCompletionDialog = false;
          this.$emit("qualityControlPassed");
        });
      } catch (err) {
        if (err.message === "1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low") {
          this.isShowError = true;
        }
      }
    },
    closeQcCompletionDialogReject() {
      this.qcCompletionDialog = false;
      this.rejectionDialog = true;
    },
    closeRejectionDialog() {
      this.rejectionDialog = false;
      this.rejectionStatementDialog = true;
    },
    closeRejectionStatementDialog() {
      if (!this.$refs.rejectForm.validate()) {
        return;
      }
      this.rejectionStatementDialog = false;
      this.rejectionConfirmationDialog = true;
    },
    backToRejectionStatementDialog() {
      this.rejectionConfirmationDialog = false;
      this.rejectionStatementDialog = true;
    },
    async submitRejectionStatementDialog() {
      if (!this.$refs.rejectForm.validate()) {
        return;
      }
      try {
        this.isLoading = true;
        await rejectDnaSample(this.api, this.pair, {
          trackingId: this.specimenNumber,
          rejected_title: this.rejectionTitle,
          rejected_description: this.rejectionDescription
        }, () => {
          this.isLoading = false;
          this.rejectionConfirmationDialog = false;
          this.rejectionAlertDialog = true;
          this.$emit("rejectSpecimen");
        });
        this.processDnaSample();
      } catch (err) {
        if (err.message === "1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low") {
          this.isShowError = true;
        }
      }
    },
    async processDnaSample() {
      this.isProcessing = true;
      await processDnaSample(this.api, this.pair, this.specimenNumber, "Rejected", () => {
        this.isProcessing = false;
      });
    }
  }
};