//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from "vuex";
import Dialog from "@/components/Dialog";
import DialogAlert from "@/components/Dialog/DialogAlert";
import Button from "@/components/Button";
import { processDnaSample, processDnaSampleFee } from "@/lib/polkadotProvider/command/geneticTesting";
import DialogErrorBalance from "@/components/Dialog/DialogErrorBalance";
export default {
  name: "WetWork",
  components: {
    Dialog,
    DialogAlert,
    DialogErrorBalance,
    Button
  },
  props: {
    specimenNumber: String
  },
  data: () => ({
    isProcessing: false,
    isShowError: false,
    wetWorkDialog: false,
    wetWorkAlertDialog: false,
    nextStatus: "WetWork",
    fee: 0
  }),
  computed: {
    ...mapGetters({
      api: "substrate/getAPI",
      pair: "substrate/wallet"
    }),
    ...mapState({
      web3: state => state.metamask.web3
    })
  },
  async mounted() {
    await this.getProcessDnaSampleFee();
  },
  methods: {
    async processDnaSample() {
      try {
        this.isProcessing = true;
        await processDnaSample(this.api, this.pair, this.specimenNumber, this.nextStatus, () => {
          this.isProcessing = false;
          this.wetWorkDialog = false;
          this.wetWorkAlertDialog = true;
        });
      } catch (err) {
        if (err.message === "1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low") {
          this.isShowError = true;
        }
      }
    },
    closeDialog() {
      this.isShowError = false;
    },
    closeWetWorkAlertDialog() {
      this.$emit("wetWorkCompleted");
    },
    async getProcessDnaSampleFee() {
      const fee = await processDnaSampleFee(this.api, this.pair, this.specimenNumber, this.nextStatus);
      this.fee = this.web3.utils.fromWei(String(fee.partialFee), "ether");
    }
  }
};