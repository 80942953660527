import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"dg-card mt-5",attrs:{"elevation":"0","outlined":""}},[_c('DialogErrorBalance',{attrs:{"show":_vm.isShowError},on:{"close":_vm.closeDialog}}),_c('div',{staticClass:"px-8 mt-5"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"mb-5"},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" Estimated Transaction Weight ")]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"primary","size":"14"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}])},[_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v("Total fee paid in DBIO to execute this transaction.")])])],1),_c('div',[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(Number(_vm.fee).toFixed(4))+" DBIO ")])])])]),_c(VForm,{staticClass:"d-flex px-8",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"dense":"","label":"Confirm Specimen Number","placeholder":"Confirm Specimen Number","outlined":"","rules":[_vm.confirmSpecimenNumberRule(_vm.specimenNumber)]},model:{value:(_vm.confirmSpecimenNumber),callback:function ($$v) {_vm.confirmSpecimenNumber=$$v},expression:"confirmSpecimenNumber"}}),_c(VBtn,{staticClass:"ml-5",staticStyle:{"width":"35%"},attrs:{"color":"primary","large":"","disabled":!(_vm.specimenNumber == _vm.confirmSpecimenNumber),"loading":_vm.isLoading},on:{"click":_vm.receiveDnaSample}},[_vm._v("RECEIVE SPECIMEN")])],1),_c('DialogAlert',{attrs:{"show":_vm.specimenAlertDialog,"btnText":"Continue","textAlert":"Specimen has been received","imgPath":"success.png","imgWidth":"50"},on:{"toggle":function($event){_vm.specimenAlertDialog = $event},"close":function($event){return _vm.$emit('specimenReceived')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }