export async function processDnaSample(api, pair, trackingId, processStatus) {
  let callback = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : () => {};
  const unsub = await api.tx.geneticTesting.processDnaSample(trackingId, processStatus).signAndSend(pair, {
    nonce: -1
  }, _ref => {
    let {
      events,
      status
    } = _ref;
    return successCallback(api, {
      events,
      status,
      callback,
      unsub
    });
  });
}
export async function processDnaSampleFee(api, pair, trackingId, processStatus) {
  const result = await api.tx.geneticTesting.processDnaSample(trackingId, processStatus).paymentInfo(pair);
  return result;
}
export async function receiveDnaSample(api, pair, trackingId) {
  let callback = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : () => {};
  const unsub = await api.tx.geneticTesting.receiveDnaSample(trackingId).signAndSend(pair, {
    nonce: -1
  }, _ref2 => {
    let {
      events,
      status
    } = _ref2;
    return successCallback(api, {
      events,
      status,
      callback,
      unsub
    });
  });
}
export async function receiveDnaSampleFee(api, pair, trackingId) {
  const result = await api.tx.geneticTesting.receiveDnaSample(trackingId).paymentInfo(pair);
  return result;
}
export async function rejectDnaSample(api, pair, data) {
  let callback = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : () => {};
  const unsub = await api.tx.geneticTesting.rejectDnaSample(data.trackingId, data.rejected_title, data.rejected_description).signAndSend(pair, {
    nonce: -1
  }, _ref3 => {
    let {
      events,
      status
    } = _ref3;
    return successCallback(api, {
      events,
      status,
      callback,
      unsub
    });
  });
}
export async function rejectDnaSampleFee(api, pair, data) {
  const result = await api.tx.geneticTesting.rejectDnaSample(data.trackingId, data.rejected_title, data.rejected_description).paymentInfo(pair);
  return result;
}
export async function submitIndependentTestResult(api, pair, submission) {
  let callback = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : () => {};
  const unsub = await api.tx.geneticTesting.submitIndependentTestResult(submission).signAndSend(pair, {
    nonce: -1
  }, _ref4 => {
    let {
      events,
      status
    } = _ref4;
    return successCallback(api, {
      events,
      status,
      callback,
      unsub
    });
  });
}
export async function submitIndependentTestResultFee(api, pair, submission) {
  const result = await api.tx.geneticTesting.submitIndependentTestResult(submission).paymentInfo(pair);
  return result;
}
export async function submitTestResult(api, pair, trackingId, submission) {
  let callback = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : () => {};
  const unsub = await api.tx.geneticTesting.submitTestResult(trackingId, submission).signAndSend(pair, {
    nonce: -1
  }, _ref5 => {
    let {
      events,
      status
    } = _ref5;
    return successCallback(api, {
      events,
      status,
      callback,
      unsub
    });
  });
}
export async function submitTestResultFee(api, pair, trackingId, submission) {
  const result = await api.tx.geneticTesting.submitTestResult(trackingId, submission).paymentInfo(pair);
  return result;
}
function successCallback(api, _ref6) {
  let {
    events,
    status,
    callback,
    unsub
  } = _ref6;
  if (status.isFinalized) {
    // find/filter for success events
    const eventList = events.filter(_ref7 => {
      let {
        event
      } = _ref7;
      return api.events.system.ExtrinsicSuccess.is(event);
    });
    if (eventList.length > 0) {
      callback();
      unsub();
    }
  }
}