//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from "vuex";
import ReceiveSpecimen from "./ReceiveSpecimen";
import QualityControlSpecimen from "./QualityControlSpecimen";
import ProcessSpecimen from "./ProcessSpecimen";
import WetWorkSpecimen from "./WetWorkSpecimen";
import { getOrdersDetail } from "@/lib/polkadotProvider/query/orders";
import DialogAlert from "@/components/Dialog/DialogAlert";
import Stepper from "@/components/Stepper";
import { queryDnaTestResults } from "@/lib/polkadotProvider/query/geneticTesting";
import { queryServicesById } from "@/lib/polkadotProvider/query/services";
import getEnv from "@/utils/env";
export default {
  name: "ProcessOrderHistory",
  components: {
    ReceiveSpecimen,
    QualityControlSpecimen,
    WetWorkSpecimen,
    ProcessSpecimen,
    DialogAlert,
    Stepper
  },
  data: () => ({
    publicKey: "",
    createdAt: "",
    customerAddress: "",
    specimenNumber: "",
    specimenStatus: "",
    serviceName: "",
    serviceDescription: "",
    serviceImage: "",
    cancelledOrderDialog: false,
    showRejectDialog: false,
    showResultDialog: false,
    isSubmitted: false,
    stepperItems: [{
      name: "Received",
      selected: false
    }, {
      name: "Quality Control",
      selected: false
    }, {
      name: "Analyzed",
      selected: false
    }, {
      name: "Upload Result",
      selected: false
    }, {
      name: "Results Ready",
      selected: false
    }],
    dnaCollectionProcess: "",
    isBiological: false,
    testResult: null,
    esscrowAddress: ""
  }),
  computed: {
    ...mapState({
      genome: state => state.testResult.genome,
      report: state => state.testResult.report,
      wallet: state => state.substrate.wallet
    }),
    ...mapGetters({
      api: "substrate/getAPI",
      pair: "substrate/wallet"
    }),
    orderId() {
      return this.$route.params.orderId ? this.$route.params.orderId : "";
    },
    showReceiveDialog() {
      return this.stepperItems.some(item => item.name == "Received" && item.selected == false);
    },
    showQualityControlDialog() {
      return this.stepperItems.some(item => item.name == "Received" && item.selected == true) && this.stepperItems.some(item => item.name == "Quality Control" && item.selected == false) || this.specimenStatus == "Rejected";
    },
    showWetWorkDialog() {
      return this.stepperItems.some(item => item.name == "Quality Control" && item.selected == true) && this.stepperItems.some(item => item.name == "Analyzed" && item.selected == false) && this.specimenStatus != "Rejected";
    },
    showGenomeReportDialog() {
      return this.stepperItems.some(item => item.name == "Analyzed" && item.selected == true) && this.stepperItems.some(item => item.name == "Upload Result" && item.selected == false) || this.stepperItems.some(item => item.name == "Upload Result" && item.selected == true) && this.stepperItems.some(item => item.name == "Results Ready" && item.selected == false) && this.specimenStatus != "Rejected";
    },
    _icon() {
      return this.serviceImage && (this.serviceImage.startsWith("mdi") || this.serviceImage.startsWith("$")) ? this.serviceImage : false;
    }
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler: async function (val) {
        try {
          await this.prefillOrder(val.params.orderId);
        } catch (err) {
          console.error(err);
        }
      }
    }
  },
  async created() {
    this.esscrowAddress = getEnv("VUE_APP_DEBIO_ESSCROW_ADDRESS");
    await this.prefillOrder(this.$route.params.orderId);
  },
  methods: {
    async prefillOrder(oid) {
      try {
        // If no order id redirect to Payment History list
        if (!oid) this.$router.push({
          name: "lab-dashboard-order-history"
        });
        const order = await getOrdersDetail(this.api, oid);
        const serviceInfo = await queryServicesById(this.api, order.serviceId);
        const testResult = await queryDnaTestResults(this.api, order.dnaSampleTrackingId);
        this.dnaCollectionProcess = serviceInfo.info.dnaCollectionProcess;
        if (this.dnaCollectionProcess.includes("Covid")) {
          this.isBiological = true;
        }
        if (order.status == "Cancelled") {
          this.cancelledOrderDialog = true;
        }
        this.serviceName = order.service_name;
        this.serviceDescription = order.service_description;
        this.serviceImage = order.service_image;
        this.publicKey = order.customerBoxPublicKey;
        this.createdAt = order.createdAt;
        this.customerAddress = order.customerId;
        this.specimenNumber = order.dnaSampleTrackingId;
        this.specimenStatus = order.dna_sample_status;
        if (testResult) this.testResult = testResult;
        this.setCheckboxByDnaStatus();
      } catch (err) {
        console.error(err);
      }
    },
    async setCheckboxByDnaStatus() {
      var _this$testResult, _this$testResult2;
      if (this.specimenStatus == "Arrived") {
        this.onSpecimenReceived();
        if (this.isBiological == true) {
          this.onQcCompleted();
        }
      }
      if (this.specimenStatus == "Rejected") {
        this.showRejectDialog = true;
        this.onQcCompleted();
      }
      if (this.specimenStatus == "QualityControlled") {
        this.onQcCompleted();
      }
      if ((_this$testResult = this.testResult) !== null && _this$testResult !== void 0 && _this$testResult.reportLink && (_this$testResult2 = this.testResult) !== null && _this$testResult2 !== void 0 && _this$testResult2.resultLink) {
        this.setUploadFields(this.testResult);
      }
      if (this.specimenStatus == "WetWork") {
        this.onWetWorkCompleted();
      }
      if (this.specimenStatus == "ResultReady") {
        this.isSubmitted = true;
        this.onResultReady();
      }
    },
    setUploadFields(testResult) {
      const {
        resultLink,
        reportLink
      } = testResult;
      if (resultLink && resultLink != "" && reportLink && reportLink != "") {
        this.onResultUploaded();
      }
    },
    onSpecimenReceived() {
      this.setStepperSelected(["Received"], true);
    },
    onQcCompleted() {
      this.setStepperSelected(["Received", "Quality Control"], true);
    },
    onWetWorkCompleted() {
      this.setStepperSelected(["Received", "Quality Control", "Analyzed"], true);
    },
    onResultUploaded() {
      this.setStepperSelected(["Received", "Quality Control", "Analyzed", "Upload Result"], true);
    },
    onResultReady() {
      this.showResultDialog = true;
      this.setStepperSelected(["Received", "Quality Control", "Analyzed", "Upload Result", "Results Ready"], true);
    },
    getImageLink(val) {
      if (val && val != "") {
        return val;
      }
      return "https://ipfs.io/ipfs/QmaGr6N6vdcS13xBUT4hK8mr7uxCJc7k65Hp9tyTkvxfEr";
    },
    setStepperSelected(names, selected) {
      this.stepperItems = this.stepperItems.map(item => {
        if (names.includes(item.name)) {
          return {
            ...item,
            selected
          };
        }
        return {
          ...item
        };
      });
    }
  }
};