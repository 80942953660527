export async function createOrder(api, pair, serviceId, customerBoxPublicKey, priceIndex) {
  const result = await api.tx.orders.createOrder(serviceId, priceIndex, customerBoxPublicKey).signAndSend(pair, {
    nonce: -1
  });
  return result.toHuman();
}
export async function fulfillOrder(api, pair, orderId) {
  let callback = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : () => {};
  const unsub = await api.tx.orders.fulfillOrder(orderId).signAndSend(pair, {
    nonce: -1
  }, _ref => {
    let {
      events,
      status
    } = _ref;
    return successCallback(api, {
      events,
      status,
      callback,
      unsub
    });
  });
}
export async function fullfillOrderFee(api, pair, orderId) {
  const result = await api.tx.orders.fulfillOrder(orderId).paymentInfo(pair);
  return result;
}
export async function refundOrder(api, pair, orderId) {
  const result = await api.tx.orders.refundOrder(orderId).signAndSend(pair, {
    nonce: -1
  });
  return result.toHuman();
}
export async function setOrderPaid(api, pair, orderId) {
  const result = await api.tx.orders.setOrderPaid(orderId).signAndSend(pair, {
    nonce: -1
  });
  return result.toHuman();
}
export async function cancelOrder(api, pair, orderId) {
  const result = await api.tx.orders.cancelOrder(orderId).signAndSend(pair, {
    nonce: -1
  });
  return result.toHuman();
}
function successCallback(api, _ref2) {
  let {
    events,
    status,
    callback,
    unsub
  } = _ref2;
  if (status.isFinalized) {
    // find/filter for success events
    const eventList = events.filter(_ref3 => {
      let {
        event
      } = _ref3;
      return api.events.system.ExtrinsicSuccess.is(event);
    });
    if (eventList.length > 0) {
      callback();
      unsub();
    }
  }
}